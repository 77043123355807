.atb-component {
    width: 100%;
    position: relative;
    margin-top: 30px;
}

.atb-component form .toggle {
    width: 320px;
    height: 48px;
    padding: 5px;
    overflow: hidden;
    background-color: #03632380;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
}

.atb-component form .toggle.three-toggle {
    width: 670px;
}

.atb-component form .toggle>div {
    width: 250px;
    height: 100%;
    cursor: pointer;
    color: #fff;
    font-family: ibm;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.atb-component form .toggle>div.active-tog {
    background-color: #036323;
}

.atb-component form .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.atb-component form .btn-wrap2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.atb-component .checkbox-wrapper {
    display: flex;
    flex-direction: column;
}

.atb-component .checkbox-wrapper>div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.atb-component .checkbox-wrapper .react-select-inp {
    width: 300px;
    margin: 0 10px;
}

.atb-component .checkbox-wrapper .react-select-inp-w {
    width: 100%;
    max-width: 620px;
}

.atb-component .checkbox-wrapper>div label {
    font-size: 18px;
}

.atb-component form .btn-wrap input {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    margin-right: 10px;
}

.atb-component form .btn-wrap button {
    width: 300px;
    height: 48px;
    background-color: #036323;
    font-size: 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: ibm;
    color: #fff;
}

.atb-component form .added-codes {
    width: 100%;
    display: grid;
    grid-template-columns: 19% 19% 19% 19% 19%;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.atb-component form .added-codes>div {
    background-color: #00000010;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    position: relative;
    color: #036323;
}

.atb-component form .added-codes>div .icon {
    font-size: 24px;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
}

.atb-component form .added-codes>div .icon:hover {
    color: red;
}

.atb-component form .added-codes>div p {
    margin: 0;
    font-size: 18px;
    font-family: ibm;
}

.atb-component form .search-wrap {
    width: 300px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
}

.atb-component form .search-wrap input {
    width: 100%;
    height: 36px;
    border: 1px solid #03632380;
    border-radius: 4px;
    outline: none;
    padding: 10px;
    font-size: 16px;
    font-family: ibm;
}

.atb-component form .add-icon {
    width: 48px;
    height: 48px;
    background-color: #036323;
    border-radius: 4px;
    color: #fff;
    display: grid;
    place-content: center;
    font-size: 28px;
    margin-bottom: 10px;
    cursor: pointer;
    /* margin-left: auto; */
}

.atb-component form .table-wrap {
    width: 100%;
    height: 600px;
    overflow-x: auto;
}

.atb-component form .table-wrap .id-wrap {
    position: relative;
}

.atb-component form .table-wrap .id-wrap p {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #036323;
}

.atb-component form .table-wrap.table-wrap-second {
    height: 600px;
    overflow-x: auto;
}

.atb-component form .table-wrap table thead {
    position: sticky;
    top: 0;
}

.atb-component form .table-wrap table thead tr {
    background-color: #EFEFEF;
    border: 1px solid #036323;
}

.atb-component form .table-wrap table {
    width: 100%;
    border: 1px solid #036323;
    border-radius: 4px;
    font-size: 18px;
    font-family: ibm;
    text-align: center;
}

.atb-component form .table-wrap table input {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.atb-component form .table-wrap table p {
    margin: 0;
}

.atb-component form .table-wrap table .delete-btn {
    width: 200px;
    height: 36px;
    border: none;
    background-color: red;
    color: #fff;
    font-size: 18px;
    font-family: ibm;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: auto;
}

.atb-component form .table-wrap-second table .codes>div {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    font-size: 13px;
}

.atb-component form .table-wrap-second table .icons-wrap .icon-wrap {
    display: inline-block;
    margin: 0 10px;
    width: 32px;
    height: 32px;
    background-color: red;
    border-radius: 4px;
    cursor: pointer;
    color: #ffffff;
}

.atb-component form .table-wrap-second table .icons-wrap .icon-wrap:nth-child(2) {
    background-color: #036323;
}

.atb-component form .table-wrap table tr:nth-child(2n + 2) {
    background-color: #00000010;
}

.atb-component form .table-wrap table th {
    border: 1px solid #03632380;
    padding: 10px;
}

.atb-component form .table-wrap table td {
    border: 1px solid #03632380;
    padding: 10px;
}

.atb-component form .table-wrap table .add-div {
    width: 200px;
    height: 36px;
    border: none;
    /* background-color: #036323; */
    color: #fff;
    font-size: 18px;
    font-family: ibm;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: auto;
}

.atb-component .h2-wrap {
    display: flex;
    width: 350px;
    margin-bottom: 30px;
    justify-content: space-between;
}

.atb-component .h2-wrap h2 {
    width: 48%;
    text-align: center;
    font-size: 24px;
    padding: 10px;
    background-color: #03632320;
    font-family: ibm;
    margin: 0;
    border-radius: 8px;
    cursor: pointer;
}

.atb-component .h2-wrap h2.active-h2 {
    background-color: #036323;
    color: #fff;
}

.label-input-wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.label-input-wrap label {
    font-size: 18px;
    color: #036323;
    font-family: ibm;
}

.label-input-wrap input {
    width: 100%;
    height: 36px;
    border: 1px solid #03632380;
    border-radius: 4px;
    outline: none;
    padding: 5px;
    font-family: ibm;
    font-size: 16px;
    color: #036323;
}

.next-div {
    width: 100%;
    background-color: #036323;
    height: 36px;
    border-radius: 4px;
    display: grid;
    place-content: center;
    color: #fff;
    font-family: ibm;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
}

h5 {
    color: #036323;
    font-family: ibm;
    font-size: 16px;
}

.modal-table-wrap {
    width: 100%;
    overflow-x: auto;
    height: 400px;
}

.modal-table {
    width: 100%;
    border: 1px solid #03632380;
    margin-bottom: 20px;
}

.modal-table td {
    border: 1px solid #03632380;
    padding: 5px;
    text-align: center;
    font-size: 16px;
    font-family: ibm;
}

.modal-table .next-div {
    width: 50px;
    margin: auto;
    font-size: 28px;
}

.search-input {
    width: 150px;
    margin-bottom: 10px;
    height: 32px;
    border: 1px solid #03632380;
    border-radius: 4px;
    font-family: ibm;
    font-size: 16px;
    color: #036323;
    outline: none;
    padding: 5px;
}

.added-items {
    width: 100%;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    gap: 10px;
    margin-bottom: 20px;
}

.added-items>div {
    background-color: #00000010;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.3s;
    font-size: 16px;
    font-family: ibm;
}

.added-items>div:hover {
    background-color: red;
    color: #fff;
}

.clear-btn {
    width: 100px;
    height: 36px;
    background-color: red;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.selects-wrap {
    width: 350px;
    height: 36px;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.selects-wrap .select-option {
    width: 49%;
    height: 100%;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: 500;
    border-radius: 4px;
    margin-bottom: 20px;
}

@media (max-width: 1400px) {
    .atb-component form .kods .all-codes {
        display: grid;
        grid-template-columns: auto auto auto auto;
    }

    .atb-component form .toggle {
        width: 250px;
        height: 40px;
        padding: 5px;
        margin-top: 20px;
    }

    .atb-component form .toggle>div {
        width: 120px;
        font-size: 16px;
        border-radius: 4px;
    }

    .atb-component form .btn-wrap input {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .atb-component form .btn-wrap button {
        width: 250px;
        height: 41px;
        font-size: 20px;
    }

    .atb-component .h2-wrap h2 {
        font-size: 18px;
    }

    .selects-wrap {
        width: 280px;
        margin-left: auto;
    }
}

@media (max-width: 992px) {
    .atb-component form .kods .all-codes {
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .atb-component form .kods h3 {
        font-size: 16px;
        line-height: 18px;
    }

    .atb-component form .kods .top-wrap input {
        width: 160px;
        height: 32px;
        font-size: 14px;
    }

    .atb-component form .kods input {
        width: 20px;
        height: 20px;
    }

    .atb-component form .kods label {
        font-size: 14px;
        margin-left: 5px;
    }

    .atb-component form .toggle {
        width: 220px;
        height: 36px;
        margin-top: 20px;
    }

    .atb-component form .btn-wrap input {
        width: 32px;
        height: 32px;
        margin-right: 10px;
    }

    .atb-component form .btn-wrap button {
        width: 250px;
        height: 33px;
        font-size: 18px;
    }
}


@media (max-width: 768px) {
    .react-select-wrap {
        display: flex;
        flex-direction: column;
        margin: 0 !important;
    }

    .atb-component .checkbox-wrapper .react-select-inp {
        min-width: 300px;
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .atb-component form .kods .all-codes {
        display: grid;
        grid-template-columns: auto auto;
    }

    .atb-component form .kods {
        padding: 20px 10px;
    }

    .atb-component .h2-wrap {
        width: 100%;
    }

    .atb-component .h2-wrap h2 {
        font-size: 16px;
    }

    .atb-component form .search-wrap {
        width: 100%;
    }

    .atb-component form .toggle.three-toggle {
        width: 100%;
    }

    .atb-component form .toggle.three-toggle>div {
        font-size: 12px;
    }

    .select-option {
        width: 100%;
        height: 36px;
    }

    .selects-wrap {
        width: 100%;
    }
}