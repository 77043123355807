.edit-modal input {
    width: 100%;
    height: 36px;
    border: 1px solid #00000050;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
    padding: 10px;
}

.edit-modal .add-code-btn {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    color: #fff;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--primary-color);
    margin-top: 10px;
}

.edit-modal ul {
    margin-top: 5px;
    max-height: 200px;
    background-color: #00000010;
    border-radius: 4px;
    padding: 10px 30px;
    overflow-y: auto;
}

.edit-modal li {
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
    font-weight: 600;
}

.edit-modal li:hover {
    color: red;
}

.edit-modal button {
    width: 100%;
    height: 36px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 4px;
    font-size: 18px;
    color: #fff;
}