@keyframes ldio-ycpqmfwc53k {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.loader-component {
    width: 100%;
    text-align: center;
}
.ldio-ycpqmfwc53k div {
  position: absolute;
  width: 75px;
  height: 75px;
  border: 15px solid #036323;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-ycpqmfwc53k div {
  animation: ldio-ycpqmfwc53k 1s linear infinite;
  top: 75px;
  left: 75px
}
.loadingio-spinner-rolling-iq9sdmeaqpb {
  width: 150px;
  height: 150px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}
.ldio-ycpqmfwc53k {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ycpqmfwc53k div { box-sizing: content-box; }