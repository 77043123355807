.login-component {
    width: 100%;
    height: 100vh;
    position: relative;
}

.login-component form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border: 1px solid #036323; */
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 30px;
    border-radius: 4px;
}

.login-component form > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.login-component form > div label {
    font-size: 18px;
    font-family: ibm;
    color: #036323;
}

.login-component form > div input {
    width: 300px;
    height: 36px;
    border: 1px solid #036323;
    border-radius: 4px;
    font-family: ibm;
    font-size: 18px;
    outline: none;
    padding: 5px;
}

.login-component form button {
    width: 100%;
    height: 36px;
    border: none;
    background-color: #036323;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    font-family: ibm;
}