.home-component {
    width: 100%;
    min-height: 100vh;
    background-color: #ffffff;
    padding: 20px;
}

.home-component .nav-link-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.home-component .nav-link-wrap .logo-wrap {
    display: flex;
    align-items: center;
}

.home-component .nav-link-wrap .logo-wrap img {
    width: 80px;
    margin-right: 10px;
}

.home-component .nav-link-wrap .logo-wrap h1 {
    font-size: 20px;
    font-family: ibm;
    text-transform: uppercase;
    color: #036323;
}

.home-component .nav-link-wrap .navs {
    width: 800px;
    display: flex;
    justify-content: flex-end;
}

.home-component .nav-link-wrap .navs > div {
    width: 24%;
    margin: 0.5%;
    height: 48px;
    background-color: #03632370;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ibm;
    font-size: 18px;
    cursor: pointer;
    border-radius: 8px;
}

.home-component .nav-link-wrap .navs > div.active {
    background-color: #036323;
}

.home-component .topp {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.home-component .topp .inp-wrap {
    width: 350px;
    display: flex;
    justify-content: space-between;
}

.home-component .topp .inp-wrap input {
    width: 49%;
    height: 36px;
    border: 1px solid #03632380;
    outline: none;
    border-radius: 4px;
    color: #036323;
    font-family: ibm;
    padding: 10px;
}

@media (max-width: 1400px) {
    .home-component .nav-link-wrap .clock {
        width: 100px;
        height: 48px;
    }
    .home-component .nav-link-wrap .navs {
        width: 600px;
    }
    .home-component .nav-link-wrap .navs > div {
        width: 24%;
        margin: 0.5%;
        height: 36px;
        font-size: 16px;
    }
    .home-component .topp .inp-wrap {
        width: 280px;
    }
    .home-component .nav-link-wrap .logo-wrap img {
        width: 70px;
        margin-right: 10px;
    }
    .home-component .nav-link-wrap .logo-wrap h1 {
        font-size: 18px;
    }
}

@media (max-width: 992px) {
    .home-component .nav-link-wrap .clock {
        width: 80px;
        height: 36px;
    }
    .home-component .nav-link-wrap .navs {
        width: 450px;
    }
    .home-component .nav-link-wrap .navs > div {
        width: 24%;
        margin: 0.5%;
        height: 36px;
        font-size: 12px;
    }
    .home-component .nav-link-wrap .logo-wrap img {
        width: 60px;
        margin-right: 10px;
    }
    .home-component .nav-link-wrap .logo-wrap h1 {
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .home-component .nav-link-wrap {
        width: 100%;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .home-component .nav-link-wrap .clock {
        margin-bottom: 20px;
        margin-left: auto;
        width: 80px;
        height: 32px;
    }
    .home-component .nav-link-wrap .navs {
        width: 100%;
    }
    .home-component .topp .inp-wrap {
        width: 100%;
    }
    .home-component .nav-link-wrap .logo-wrap {
        justify-content: center;
        margin-bottom: 10px;
    }
    .home-component .nav-link-wrap .logo-wrap img {
        width: 50px;
        margin-right: 10px;
    }
    .home-component .nav-link-wrap .logo-wrap h1 {
        font-size: 12px;
    }
}