@font-face {
    font-family: ibm;
    src: url('./assets/fonts/IBMPlexSans-Medium.ttf');
}

:root {
    --primary-color: #036323;
}

body {
    margin: 0;
}

* {
    box-sizing: border-box;
}

body::-webkit-scrollbar {
    width: 0;
}

h1 {
    font-family: ibm;
    color: var(--primary-color);
    text-align: center;
}